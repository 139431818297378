import { useTranslation } from 'react-i18next';
import { ExportSmsFragment } from 'types/generated/graphql';
import { utils, writeFile } from 'xlsx';

const FILE_NAME = 'sent-sms-export.xlsx';

type TranslationData = Record<string, string | null | number>;

export const useExportSmsLogsToExcel = () => {
  const { t } = useTranslation();

  const exportSmsLogsToExcel = (array: ExportSmsFragment[]) => {
    const translatedData =
      array.map((row) => {
        return Object.entries(row).reduce<TranslationData>(
          (acc, [key, value]) => {
            acc[t(`SmsExport.Columns.${key}`) as string] = value;
            return acc;
          },
          {}
        );
      }) || [];

    const wscols = Object.keys(array[0]).map(() => {
      return { wch: 30 };
    });

    const newBook = utils.book_new();
    const newSheet = utils.json_to_sheet(translatedData);
    newSheet['!cols'] = wscols;

    utils.book_append_sheet(newBook, newSheet, t('SmsExport.SHEET_NAME'));

    writeFile(newBook, FILE_NAME);
  };

  return { exportSmsLogsToExcel };
};
