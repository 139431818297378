import { Flex, Text } from '@conteg/ui';
import { isNil } from 'lodash';

export const InfoText = ({
  label,
  value,
}: {
  label: string;
  value?: string | number | null;
}) => {
  if (isNil(value)) {
    return null;
  }

  return (
    <Flex gap="1rem">
      <Text variant="highlight" content={`${label}:`} /> {value}
    </Flex>
  );
};
