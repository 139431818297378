import {
  getGraphQLRequestName,
  parseGraphQLErrorMessage,
} from '@conteg/logging';
import { GqlError } from '@conteg/ui';
import { getToken } from 'utils/get-token/get-token';
import { logger } from 'utils/logging/init';

type Variables = {
  accessPin?: string;
  [value: string]: unknown;
};

export function fetcher<TData, TVariables extends Variables>(
  query: string,
  variables?: TVariables
) {
  return async (): Promise<TData> => {
    const token = getToken();
    const graphQLRequestName = getGraphQLRequestName(query.trim());

    const body = JSON.stringify({ query, variables });

    const res = await fetch(window.contegConfig.VITE_GQL_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': window.origin,
        'Access-Control-Request-Headers': 'X-Requested-With',
        'Access-Control-Request-Method': 'POST',
        authorization: `Bearer ${token}`,
      },
      body,
    });

    const json = await res.json();

    if (json.errors) {
      const formattedErrors = json.errors
        .map(parseGraphQLErrorMessage)
        .join('\n');

      const errorMessage = `Following errors has occurred while fetching data for ${graphQLRequestName} with body\n ${body}\n${formattedErrors}`;

      await logger.error(errorMessage);

      throw new GqlError(
        variables,
        graphQLRequestName,
        json.errors,
        formattedErrors,
        res.status
      );
    }

    return json.data;
  };
}
