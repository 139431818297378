import { Flex, Heading, styled } from '@conteg/ui';
import { ReactNode } from 'react';

type PageLayoutProps = {
  title: string;
  children: ReactNode;
};

export const PAGE_LAYOUT_PADDING_IN_REM = 10;

export const PAGE_LAYOUT_WIDTH_CONTENT_PADDING_IN_REM = 5;

export const PageLayout = ({ title, children }: PageLayoutProps) => {
  return (
    <Container>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex mb="6rem" width="100%" gap="4rem" alignItems="center">
          <Heading title={title} variant="h1" testId="pageTitle" />
        </Flex>
      </Flex>
      {children}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

export const PageLayoutContent = styled.div`
  border-radius: 3rem;
  padding: 6rem ${PAGE_LAYOUT_WIDTH_CONTENT_PADDING_IN_REM}rem;
  background: white;
  width: 100%;
`;
