import { authLinks, AuthRoutes, useAuth, useUserData } from '@conteg/auth';
import { Navbar as ContegUiNavbar } from '@conteg/ui';
import { usePolicyEvaluate } from 'components/policy-loader/policy-loader';
import { CUSTOM_AUTH_TOKEN } from 'config';
import env from 'env/env';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'utils/routing/route-paths';

export const Navbar = () => {
  const { t } = useTranslation();
  const userData = useUserData(CUSTOM_AUTH_TOKEN);
  const { signoutRedirect } = useAuth();

  const canDisplaySentSms = usePolicyEvaluate('displaySentSms', 'audit');

  const navigate = useNavigate();

  const titles: Record<AuthRoutes, string> = {
    [AuthRoutes.CHANGE_PASSWORD]: t('NavBar.Link.ChangePassword'),
    [AuthRoutes.MY_PROFILE]: t('NavBar.Link.MyProfile'),
  };
  const authMenuLinks = authLinks.map((link) => ({
    label: titles[link.route],
    onClick: () => {
      window.open(`${env.VITE_IDENTITY_WEB_URL}${link.link}`, '_blank');
    },
  }));

  const navLinks = [
    {
      label: t('NavBar.Link.Events'),
      onClick: () => navigate(RoutePaths.ContentTracking),
    },
  ];

  if (canDisplaySentSms) {
    navLinks.push({
      label: t('NavBar.Link.SentSms'),
      onClick: () => navigate(RoutePaths.SentSms),
    });
  }

  return (
    <ContegUiNavbar
      user={{
        email: userData?.email || '',
        name: userData?.fullName || '',
      }}
      navLinks={navLinks}
      menuLinks={[
        ...authMenuLinks,
        { label: t('NavBar.Link.Logout'), onClick: signoutRedirect },
      ]}
      logoLink={{
        label: t('Navbar.Logo.Label'),
        onClick: () =>
          navigate(`${RoutePaths.ContentTracking}${window.location.search}`),
      }}
    />
  );
};
