import {
  Alert,
  Box,
  Button,
  Flex,
  Heading,
  Pagination,
  Table,
  Tooltip,
} from '@conteg/ui';
import { SortingState, useReactTable } from '@tanstack/react-table';
import { createColumnHelper, getCoreRowModel } from '@tanstack/table-core';
import { PageLayout, PageLayoutContent } from 'components/basic-layout';
import { usePolicyEvaluate } from 'components/policy-loader/policy-loader';
import { TimeSelect } from 'pages/sent-sms/TimeSelect';
import { useExportSmsLogsToExcel } from 'pages/sent-sms/use-export-sms-logs-to-excel';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportSmsDto, useSentSmsLogsQuery } from 'types/generated/graphql';

const tableColumnHelper = createColumnHelper<ExportSmsDto>();

const PAGE_SIZE = 7;

const SentSms = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const smsSentAtFrom = fromDate ? new Date(fromDate) : undefined;
  const smsSentAtTo = toDate ? new Date(toDate) : undefined;

  const isOverlapping =
    smsSentAtFrom && smsSentAtTo && smsSentAtFrom > smsSentAtTo;

  const { data, isLoading, error } = useSentSmsLogsQuery(
    {
      smsSentAtFrom: smsSentAtFrom ? smsSentAtFrom.toISOString() : undefined,
      smsSentAtTo: smsSentAtTo ? smsSentAtTo.toISOString() : undefined,
    },
    { enabled: !isOverlapping }
  );

  const { t } = useTranslation();
  const { exportSmsLogsToExcel } = useExportSmsLogsToExcel();
  const [pageIndex, setPageIndex] = useState(0);
  const [isSentAtDesc, setIsSentAtDesc] = useState(true);

  useEffect(() => {
    setPageIndex(0);
  }, [fromDate, toDate]);

  const items: ExportSmsDto[] =
    data?.sentSmsLogs?.filter((item) => !!item && !!item.projectName) || [];

  const start = pageIndex * PAGE_SIZE;
  const end = start + PAGE_SIZE;

  const currentPageData = items.length > 0 ? items.slice(start, end) : [];

  const tableColumns = useMemo(
    () => [
      tableColumnHelper.accessor((row) => row.pointRegistrationNumber, {
        id: 'pointRegistrationNumber',
        cell: (info) => info.getValue(),
        header: () => (
          <span>{t('SmsExport.Columns.pointRegistrationNumber')}</span>
        ),
        enableSorting: false,
      }),
      tableColumnHelper.accessor((row) => row.tenantSubjectName, {
        id: 'tenantSubjectName',
        cell: (info) => info.getValue(),
        header: () => <span>{t('SmsExport.Columns.tenantSubjectName')}</span>,
        enableSorting: false,
      }),
      tableColumnHelper.accessor((row) => row.projectName, {
        id: 'projectName',
        cell: (info) => info.getValue(),
        header: () => <span>{t('SmsExport.Columns.projectName')}</span>,
        enableSorting: false,
      }),
      tableColumnHelper.accessor((row) => row.messagesCount, {
        id: 'recipient',
        cell: (info) => info.getValue(),
        header: () => <span>{t('SmsExport.Columns.messagesCount')}</span>,
        enableSorting: false,
      }),

      tableColumnHelper.accessor((row) => row.smsMessagesCount, {
        id: 'sentAt',
        cell: (info) => info.getValue(),
        enableSorting: false,
        header: () => (
          <Tooltip
            content={t('SmsExport.Columns.smsMessagesCount.tooltip')}
            testId="smsMessagesCount"
          >
            {t('SmsExport.Columns.smsMessagesCount')} <code>&#9432;</code>
          </Tooltip>
        ),
      }),
    ],
    [setIsSentAtDesc]
  );

  const eventsTable = useReactTable({
    data: currentPageData,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    state: {
      sorting: [
        {
          desc: isSentAtDesc,
          id: 'sentAt',
        },
      ],
    },
    onSortingChange: (sortingUpdater) => {
      const newValue = (sortingUpdater as () => SortingState)();
      setIsSentAtDesc(newValue[0].desc);
    },
  });

  return (
    <PageLayout title={t('SmsExport.Title')}>
      <PageLayoutContent>
        <Box marginBottom="6rem">
          <Heading variant="h4" title={t('SmsExport.TimeFilter')} />
          <Flex gap="4rem" alignItems="center" marginBottom="3rem">
            <TimeSelect
              defaultTime="00:00"
              onChange={(value) => setFromDate(value)}
            />
            -
            <TimeSelect
              defaultTime="23:59"
              onChange={(value) => setToDate(value)}
            />
          </Flex>
          {isOverlapping && (
            <Alert message={t('SmsExport.OverlappingError')} type="error" />
          )}
        </Box>

        <Table
          table={eventsTable}
          isEmpty={items.length === 0}
          isLoading={isLoading && !isOverlapping}
          emptyState={{
            title: t('SmsExport.EmptyState.Title'),
            description: t('SmsExport.EmptyState.Description'),
          }}
          errorInfo={{
            error,
            extendedDescription: {
              title: t('SmsExport.ErrorState.ExtendedDescription.Title'),
            },
            shortDescription: {
              title: t('SmsExport.ErrorState.ShortDescription.Title'),
              description: t(
                'SmsExport.ErrorState.ShortDescription.Description'
              ),
            },
          }}
        />
        <Box padding="3rem">
          <Pagination
            activePage={pageIndex}
            pagesCount={Math.round(items.length / PAGE_SIZE)}
            onChange={(page) => setPageIndex(page)}
            hideForOnlyOnePage={true}
          />
        </Box>
        {items.length > 0 && (
          <Flex justifyContent="flex-end" padding="4rem 0">
            <Button
              title={t('SmsExport.ExportToExcel')}
              onClick={() => exportSmsLogsToExcel(items)}
            />
          </Flex>
        )}
      </PageLayoutContent>
    </PageLayout>
  );
};

const SentSmsGuard = () => {
  const canDisplaySentSms = usePolicyEvaluate('displaySentSms', 'audit');

  if (!canDisplaySentSms) {
    return <Alert />;
  }

  return <SentSms />;
};

export default SentSmsGuard;
