import { useAuth, useAutoInitLogin } from '@conteg/auth';
import { Box, Loading } from '@conteg/ui';
import { CUSTOM_AUTH_TOKEN } from 'config';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePaths } from 'utils/routing/route-paths';

export type LoginLocationState = {
  onSuccessPath: string;
};

const isLoginLocationState = (state: unknown): state is LoginLocationState =>
  (state as LoginLocationState)?.onSuccessPath !== undefined;

const Login: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signinRedirect } = useAuth();

  const location = useLocation();

  const loginSuccessRedirect =
    (isLoginLocationState(location.state) && location.state.onSuccessPath) ||
    RoutePaths.ContentTracking;

  // Automatically init login flow
  const { isLoading, error } = useAutoInitLogin(
    loginSuccessRedirect,
    (value) => navigate(value),
    !!CUSTOM_AUTH_TOKEN
  );

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Box flexDirection="column" alignItems="center">
        <p>{t('Login.Error')}</p>
        <button type="button" onClick={() => signinRedirect()}>
          {t('login')}
        </button>
      </Box>
    );
  }

  return (
    <Box flexDirection="column" alignItems="center">
      <p>{t('Login.Info')}</p>
      <button type="button" onClick={() => signinRedirect()}>
        {t('login')}
      </button>
    </Box>
  );
};

export default Login;
