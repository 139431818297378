import { Flex, Text } from '@conteg/ui';
import { InfoText } from 'pages/events/info-text';
import { useTranslation } from 'react-i18next';
import { StorageUnitTrackingMessageFragment } from 'types/generated/graphql';

import { StorageUnitTrackingLockState } from './strorage-unit-tracking-lock-state';

type StorageUnitAdditionalInfoProps = {
  event: StorageUnitTrackingMessageFragment;
};

export const StorageUnitAdditionalInfo = ({
  event,
}: StorageUnitAdditionalInfoProps) => {
  const { t } = useTranslation();

  // TODO wait for union type https://dev.azure.com/conteg-valatron/Conteg-uschovna/_workitems/edit/13893
  //if (event.__typename === 'StorageUnitTrackingMessageDto') {
  return (
    <Flex flexDirection="row" flexWrap="wrap" gap="3rem">
      <InfoText
        label={t('StorageUnitAdditionalInfo.StorageUnitNumber')}
        value={event.storageUnitName}
      />
      <Flex gap="1rem">
        <Text
          variant="highlight"
          content={t('StorageUnitTracking.LockState')}
        />
        <StorageUnitTrackingLockState lockState={event.lockStatus} />
      </Flex>
    </Flex>
  );
  //}
};
