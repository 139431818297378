import { ThemeProvider } from '@conteg/ui';
import App from 'app';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from 'utils/auth/auth';
import QueryClientProvider from 'utils/gql/query-client-provider';
import GlobalStyles from 'utils/styles/global-styles';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider>
      <QueryClientProvider>
        <BrowserRouter>
          <AuthContextProvider>
            <App />
            <GlobalStyles />
          </AuthContextProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
);
