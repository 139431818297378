import { Flex, Input, InputTypes } from '@conteg/ui';
import { useTranslation } from 'react-i18next';

type TimeSelectProps = {
  onChange: (value: string) => void;
  value: string | undefined;
  defaultTime: string;
  isOverlapping?: boolean;
};

export const TimeSelect = ({
  onChange,
  defaultTime,
  value,
}: TimeSelectProps) => {
  const { t } = useTranslation();

  const date = value ? value.split('T')[0] : undefined;
  const time = value ? value.split('T')[1] : defaultTime;

  return (
    <Flex gap="1rem">
      <Input
        value={date || ''}
        onChange={(ev) => onChange(`${ev.target.value}T${time}`)}
        type={InputTypes.DATE}
        label={t('SmsExport.Day')}
        placeholder="dd.mm.yyyy"
      />
      <Input
        disabled={!date}
        value={time}
        onChange={(ev) => onChange(`${date}T${ev.target.value}`)}
        type={InputTypes.TIME}
        label={t('SmsExport.Time')}
        placeholder="hh:mm"
      />
    </Flex>
  );
};
