import env from 'utils/env/env';

export enum EnvironmentMode {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export const getEnvironmentMode = {
  isDevelopment: env?.NODE_ENV === EnvironmentMode.DEVELOPMENT,
  isStaging: env?.NODE_ENV === EnvironmentMode.STAGING,
  isProduction: env?.NODE_ENV === EnvironmentMode.PRODUCTION,
};
