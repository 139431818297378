import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    box-sizing: border-box;
    background-color:  #F4F5FA;
  }
`;

export default GlobalStyles;
