import { DateTime } from 'luxon';

export const formatDateTime = (date: string | undefined | null) => {
  if (!date) {
    return '';
  }
  const dateLuxon = DateTime.fromISO(date);
  const formattedDateTime = dateLuxon.toFormat('dd.MM.yyyy HH:mm:ss');
  return formattedDateTime;
};

export const formatDateTimeToIso = (date: string | undefined | null) => {
  if (!date) {
    return null;
  }
  const dateLuxon = DateTime.fromISO(date);
  const isoDateTime = dateLuxon.toISO();
  return isoDateTime;
};
