import { AUTH_CLIENT_ID, CUSTOM_AUTH_TOKEN } from 'config';
import env from 'utils/env/env';

export const getToken = () => {
  if (CUSTOM_AUTH_TOKEN) {
    return CUSTOM_AUTH_TOKEN;
  }
  const localStorageRecordAsString = localStorage.getItem(
    `oidc.user:${env.VITE_IDENTITY_API_URL}:${AUTH_CLIENT_ID}`
  );

  if (!localStorageRecordAsString) {
    return null;
  }

  try {
    const record = JSON.parse(localStorageRecordAsString);
    return record?.access_token;
  } catch (error) {
    console.error(error);
    return null;
  }
};
