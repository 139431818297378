import { Flex, Input, InputTypes } from '@conteg/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type TimeSelectProps = {
  onChange: (value: string) => void;
  defaultTime: string;
  isOverlapping?: boolean;
};

export const TimeSelect = ({ onChange, defaultTime }: TimeSelectProps) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>(defaultTime);

  useEffect(() => {
    onChange(date ? `${date} ${time}` : '');
  }, [date, time]);

  return (
    <Flex gap="1rem">
      <Input
        value={date}
        onChange={(ev) => setDate(ev.target.value)}
        type={InputTypes.DATE}
        label={t('SmsExport.Day')}
        placeholder="dd.mm.yyyy"
      />
      <Input
        disabled={!date}
        value={time}
        onChange={(ev) => setTime(ev.target.value)}
        type={InputTypes.TIME}
        label={t('SmsExport.Time')}
        placeholder="hh:mm"
      />
    </Flex>
  );
};
