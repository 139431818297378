import { Logger, Source } from '@conteg/logging';
import { getEnvironmentMode } from 'components/get-environment-mode/get-environment-mode';

import env from '../env/env';

const { isDevelopment } = getEnvironmentMode;

export const logger = new Logger({
  serviceUrl: env?.VITE_LOGGING_URL,
  source: Source.Device,
  token: '1234567890',
  enabled: !isDevelopment,
});
