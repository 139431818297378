import { Flex, styled } from '@conteg/ui';
import { ContentTracking } from 'pages/events/content-tracking/content-tracking';
import { StorageUnitTracking } from 'pages/events/storage-unit-tracking/storage-unit-tracking';
import Login from 'pages/login/login';
import SentSms from 'pages/sent-sms/sent-sms';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { ProtectedRoute } from 'utils/routing/protected-route';

import { RoutePaths } from './route-paths';

const routes: RouteProps[] = [
  { path: '/', element: <Login /> },
  { path: RoutePaths.Login, element: <Login /> },
];

const authRoutes: RouteProps[] = [
  { path: '/', element: <ContentTracking /> },
  { path: RoutePaths.ContentTracking, element: <ContentTracking /> },
  { path: RoutePaths.StorageUnitsTracking, element: <StorageUnitTracking /> },

  { path: RoutePaths.SentSms, element: <SentSms /> },
];

const FlexContainer = styled(Flex)`
  flex-direction: column;
  height: 100%;
  width: 342rem;
  max-width: 100%;
  margin: 0 auto;
`;

const pathName = window.location.pathname;

const Router: React.FC = () => {
  return (
    <FlexContainer>
      <Routes>
        {routes.map((route) => (
          <Route path={route.path} key={route.path} element={route.element} />
        ))}
        <Route>
          {authRoutes.map((route) => (
            <Route
              path={route.path}
              key={route.path}
              element={
                <ProtectedRoute path={`${pathName}${window.location.search}`}>
                  {route.element}
                </ProtectedRoute>
              }
            />
          ))}
        </Route>
      </Routes>
    </FlexContainer>
  );
};

export default Router;
