import { Tooltip } from '@conteg/ui';
import { useTranslation } from 'react-i18next';
import { LockStatus } from 'types/generated/graphql';

type StorageUnitTrackingLockStateProps = {
  lockState: LockStatus;
};

export const StorageUnitTrackingLockState = ({
  lockState,
}: StorageUnitTrackingLockStateProps) => {
  const { t } = useTranslation();

  switch (lockState) {
    case LockStatus.Closed:
      return (
        <Tooltip
          content={t('StorageUnitTracking.LockState.Closed')}
          testId="closed-lock-state-tooltip"
        >
          <code>&#128274;</code>
        </Tooltip>
      );
    case LockStatus.Opened:
      return (
        <Tooltip
          content={t('StorageUnitTracking.LockState.Opened')}
          testId="opened-lock-state-tooltip"
        >
          <code>&#10060;</code>
        </Tooltip>
      );
    case LockStatus.Failed:
      return (
        <Tooltip
          content={t('StorageUnitTracking.LockState.Failed')}
          testId="failed-lock-state-tooltip"
        >
          <code>&#9888;&#65039;</code>
        </Tooltip>
      );
    default:
      return null;
  }
};
