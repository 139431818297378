import { useAuth } from '@conteg/auth';
import { Flex } from '@conteg/ui';
import { Navbar } from 'components/navbar/navbar';
import { PolicyLoader } from 'components/policy-loader/policy-loader';
import { CUSTOM_AUTH_TOKEN } from 'config';
import { LoginLocationState } from 'pages/login/login';
import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { RoutePaths } from 'utils/routing/route-paths';

type ProtectedRouteProps = {
  children: ReactNode;
  path: string;
};

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, path }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated && !CUSTOM_AUTH_TOKEN) {
    return (
      <Navigate
        to={RoutePaths.Login}
        replace
        state={{ onSuccessPath: path } as LoginLocationState}
      />
    );
  }
  return (
    <PolicyLoader>
      <Navbar />
      <Flex padding="10rem">{children}</Flex>
    </PolicyLoader>
  );
};
